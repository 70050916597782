import tableComponent from './tableComponent';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'All promo codes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'all-promo-codes',
  components: {
    tableComponent
  }
};